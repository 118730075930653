<template>
              <Card class="sticky-filters mb-15 card-custom">
                <template #title>
                  <Toolbar>
                    <template #start>
                    </template>
                    <template #end>
                      <Button :label="$t('create_deposit_bonus')" icon="pi pi-plus" class="p-button-sm p-button-success" @click="$router.push('/deposit-bonuses/create')" />
                      </template>
                </Toolbar>
                </template>
                <template #content>
                  <v-chip-group>
                    <v-chip class="active-filter-chip mr-1"
                    v-if="Object.keys(filteredActiveFilters).length"
                      @click="clearAllFilters">
                      <template>
                        {{ $t('clear_all') }}
                      </template>
                      <v-icon>mdi-close</v-icon>
                    </v-chip>
                    <v-chip class="active-filter-chip mr-1" v-for="(value, key) in filteredActiveFilters" :key="'filter' + key"
                      @click="removeFilter(key)">
                      <template>
                        {{ $t(key) }}: {{ getFilterValueText(key, value) }}
                      </template>
                      <v-icon @click.stop="removeFilter(key)">mdi-close</v-icon>
                    </v-chip>
                  </v-chip-group>  
                  <DataTable 
                  :value="depositBonuses.items" 
                  :rows="depositBonuses.size"
                   :loading="loadingFlag" 
                   filterDisplay="row"
                  responsiveLayout="responsive"
                  :scrollable="true" 
                    stripedRows
                    removableSort
                    class="p-datatable-sm">
                    <Column sortable field="id" header="ID" :showFilterMenu="false" :styles="{'width': '5rem'}">
                      <template #body="{data}">
                        <div>
                          {{ data.id }}
                        </div>
                      </template>
                      <template #filter>
                      </template>
                    </Column>
                    <Column sortable field="sub_type" :header="$t('type')" :showFilterMenu="false" :styles="{'min-width': '10rem'}">
                      <template #body="{data}">
                        <div class="text-capitalize">
                          {{ getSubType(data.sub_type) }}
                        </div>
                      </template>
                      <template #filter>
                        <v-menu v-model="subTypeMenu" 
              offset-y
              :close-on-content-click="false" 
                  nudge-left="0" nudge-right="0">
                  <template v-slot:activator="{ on }">
                   
                      <span class="p-input-icon-right" v-on="on" >
                        <i class="pi pi-angle-down" />
                       
                        <div class="p-inputgroup">
                          <Button
                          v-if="filters.sub_type"
                          @click.stop="clearSelectedFilter('sub_type')"
                          icon="pi pi-times" class="p-button-danger"/>
                          <InputText 
                          :value="filters.sub_type ? getSubType(filters.sub_type) : ''"
                          type="text" readonly class="p-inputtext-sm" :placeholder="$t('type')">
                          </InputText>
                        <!-- :value="filters.currency ? filters.currency : ''" -->
                        </div>
                      </span>
                  </template>
                  <!-- <div class="input-menu__menu">
                  </div> -->
                    <Listbox v-model="filters.sub_type" :options="subTypes"
                    @change="applyFilters"
                    :emptyFilterMessage="$t('no_data')"
                    optionValue="value"
                    optionLabel="name" 
                     style="width:15rem" />
      
                </v-menu>
                      </template>
                    </Column>
                    <Column sortable field="type" :header="$t('bonus_type')" :showFilterMenu="false" :styles="{'min-width': '10rem'}">
                      <template #body="{data}">
                        <div>
                          {{ $t(data.type) }}
                        </div>
                      </template>
                      <template #filter>
                        <v-menu v-model="typeMenu" 
              offset-y
              :close-on-content-click="false" 
                  nudge-left="0" nudge-right="0">
                  <template v-slot:activator="{ on }">
                   
                      <span class="p-input-icon-right" v-on="on" >
                        <i class="pi pi-angle-down" />
                        <div class="p-inputgroup">
                          <Button
                          v-if="filters.type"
                          @click.stop="clearSelectedFilter('type')"
                          icon="pi pi-times" class="p-button-danger"/>
                          <InputText 
                        :value="filters.type ? $t(filters.type) : ''"
                        type="text" readonly class="p-inputtext-sm" :placeholder="$t('bonus_type')">
                        </InputText>
                        <!-- :value="filters.currency ? filters.currency : ''" -->
                        </div>
                      </span>
                  </template>
                  <!-- <div class="input-menu__menu">
                  </div> -->
                    <Listbox v-model="filters.type" :options="types"
                    @change="applyFilters"
                    optionValue="value"
                    optionLabel="name" 
                    :emptyFilterMessage="$t('no_data')"
                    style="width:15rem" />
      
                </v-menu>
                      </template>
                    </Column>
                    <Column field="min_dep" :header="$t('min_dep')" :showFilterMenu="false" :styles="{'min-width': '10rem'}">
                      <template #body="{data}">
                        <div class="d-flex flex-column">
                          <div v-for="(limits, currency) in data.currency_limits" :key="currency">
                            {{ currency }}: {{ limits.min_dep }}
                          </div>
                        </div>
                      </template>
                      <template #filter>
                        <div class="p-inputgroup">
                          <Button
                          v-if="filters.min_dep || filters.min_dep === 0"
                          @click.stop="clearSelectedFilter('min_dep')"
                          icon="pi pi-times" class="p-button-danger"/>
                          <InputNumber
                          v-model="filters.min_dep"
                          @input="debounceFilters"
                          showButtons  
                          :step="1"
                          :min="0"
                          >
                          </InputNumber>
                      </div>
                      </template>
                    </Column>
                    <Column field="max_dep" :header="$t('max_dep')" :showFilterMenu="false" :styles="{'min-width': '10rem'}">
                      <template #body="{data}">
                        <div class="d-flex flex-column">
                        <div v-for="(limits, currency) in data.currency_limits" :key="currency">
                          {{ currency }}: {{ limits.max_dep }}
                        </div>
                      </div>
                      </template>
                      <template #filter>
                        <div class="p-inputgroup">
                          <Button
                          v-if="filters.max_dep || filters.max_dep === 0"
                          @click.stop="clearSelectedFilter('max_dep')"
                          icon="pi pi-times" class="p-button-danger"/>
                          <InputNumber
                          v-model="filters.max_dep"
                          @input="debounceFilters"
                          showButtons  
                          :step="1"
                          :min="0"
                          >
                          </InputNumber>
                      </div>
                      </template>
                    </Column>
                    <Column sortable field="bonus_percent" :header="$t('bonus_percent')" :showFilterMenu="false" :styles="{'min-width': '8rem'}">
                      <template #body="{data}">
                        <div>
                          {{ data.bonus_percent }}%
                        </div>
                      </template>
                      <template #filter>
                      </template>
                    </Column>
                    <Column sortable field="wager" :header="$t('wager')" :showFilterMenu="false" :styles="{'min-width': '10rem'}">
                      <template #body="{data}">
                        <div>
                          x{{ data.wager }}
                        </div>
                      </template>
                      <template #filter>
                        <div class="p-inputgroup">
                          <Button
                          v-if="filters.wager || filters.wager === 0"
                          @click.stop="clearSelectedFilter('wager')"
                          icon="pi pi-times" class="p-button-danger"/>
                          <InputNumber
                          v-model="filters.wager"
                          @input="debounceFilters"
                          showButtons  
                      :step="1"
                      :min="0"
                      :max="100"
                      suffix=" %"
                          >
                          </InputNumber>
                      </div>
                      </template>
                    </Column>
                    <Column sortable field="deposit_source" :header="$t('deposit_source')" :showFilterMenu="false" :styles="{'min-width': '8rem'}">
                      <template #body="{data}">
                        <div>
                          {{ data.deposit_source }}
                        </div>
                      </template>
                      <template #filter>
                      </template>
                    </Column>
                    <Column sortable field="expire_in_days" :header="$t('expire_in_days')" :showFilterMenu="false" :styles="{'min-width': '5rem'}">
                      <template #body="{data}">
                        <div>
                          {{ data.expire_in_days }}
                        </div>
                      </template>
                      <template #filter>
                      </template>
                    </Column>
                    <Column field="currency" :header="$t('currency')" :showFilterMenu="false" :styles="{'min-width': '10rem'}">
                      <template #body="{data}">
                        <div>
                          <span v-for="(limits, currency, index) in data.currency_limits" :key="currency" class="ml-1">
                            <span>
                              {{ currency }}{{ index < Object.keys(data.currency_limits).length - 1 ? ',' : '' }}
                            </span>
                          </span>
                        </div>
                      </template>
                      <template #filter>
                      </template>
                    </Column>
                    <Column field="is_enabled" :header="$t('active')" :showFilterMenu="false" :styles="{'min-width': '8rem'}">
                      <template #body="{data}">
                        <span class="custom-badge"
                :class="getBonusActiveClass(data.is_enabled)"
                >
                  <span>{{ getBonusActive(data.is_enabled) }} </span>
                </span>
                      </template>
                      <template #filter>
                      </template>
                    </Column>
                    <!-- alignFrozen="right" :frozen="true" -->
                    <Column :exportable="false" 
                    :header="$t('actions')"
                    
                    >
                      <template #body="{data}">
                          <div class="d-flex align-center">
                            <Button icon="pi pi-pencil" :disabled="data.loadingItem" :loading="data.loadingItem" class="p-button-sm p-button-rounded p-button-warning mr-2" @click="toEditBonus(data)" />
                            <Button icon="pi pi-trash" :disabled="data.loadingItem" :loading="data.loadingItem" class="p-button-sm p-button-rounded p-button-danger" @click="toggleConfirmDeleteDialog(data)" />
                          </div>
                        </template>
                  </Column>
                  </DataTable>
                  <games-paggination 
                  :pagginationObject="filters" 
                  :requestData="depositBonuses" 
                  @apply-filters="applyFilters" 
                  :isStatic="false"
                  />
                  <!-- Delete Window -->
        <v-dialog
        v-model="confirmDeleteDialog"
        max-width="550px"
        scrollable
        :persistent="formLoading"
       
        content-class="br-16"
    >
      <v-card>
        <v-card-title class="font-weight-bold d-flex flex-column">
          <!-- <span>{{ $i18n.t('bonus_delete') }}</span> -->
          <span class="c-red">{{ $i18n.t('deleting_bonus') }}</span>
        </v-card-title>
        <v-card-text class="pb-0 pt-2">
          
          <!-- {{ deletingBonus }} -->
          <v-text-field
          v-if="deletingBonus.game_id"
                v-model="deletingBonus.game_id"
                readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('game') + ' ' + '(id)'"
            />
          <v-text-field
          v-if="deletingBonus.bet"
                v-model="deletingBonus.bet"
                readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('bet')"
                :suffix="deletingBonus.currency[0]"
            />
          
            <v-text-field
          v-if="deletingBonus.type"
          :value="$t(deletingBonus.type)"      
          readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('type')"
            />
          <v-text-field
          v-if="deletingBonus.currency"
                v-model="deletingBonus.currency"
                readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('currency')"
            />
            <v-text-field
          v-if="deletingBonus.expire_in_days"
                v-model="deletingBonus.expire_in_days"
                readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('expire_in_days')"
            />
          <v-text-field
          v-if="deletingBonus.lines"
                v-model="deletingBonus.lines"
                readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('lines')"
            />
          <v-text-field
          v-if="deletingBonus.wager"
                v-model="deletingBonus.wager"
                readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('wager')"
                prefix="x"
            />
          <v-text-field
          v-if="deletingBonus.bonus_percent"
                v-model="deletingBonus.bonus_percent"
                readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('bonus_percent')"
                suffix="%"
            />
            <v-text-field
            v-if="deletingBonus.deposit_source"
                  v-model="deletingBonus.deposit_source"
                  readonly
                  outlined
                  dense
                  hide-details
                  class="mb-3"
                  color="var(--main-flamingo)"
                  :label="$i18n.t('deposit_source')"
              />
          <v-text-field
          v-if="deletingBonus.count"
                v-model="deletingBonus.count"
                readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('spins')"
            />
          <v-text-field
          v-if="deletingBonus.mpl"
                v-model="deletingBonus.mpl"
                readonly
                outlined
                dense
                hide-details
                class="mb-3"
                color="var(--main-flamingo)"
                :label="$i18n.t('multiplier')"
            />
        </v-card-text>
        <v-card-actions>
          <v-col style="text-align: right" class="pa-0">
            <v-btn style="text-transform: none" class="main-btn" dark :loading="formLoading" @click="deleteBonus">
              <v-icon>delete</v-icon>
              {{ $i18n.t('delete') }}
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
                </template>
                
       
      </Card>
       
  </template>
  
  <script>
   import GamesPaggination from '@/views/GamesPaggination'
  import {mapGetters} from 'vuex';
  import {debounce} from "lodash"
  export default {
    name: "ViewDepositBonuses",
    components:{
      GamesPaggination
    },
    computed: {
      ...mapGetters({
        depositBonuses: 'bonuses/getDepositBonuses',
        payments: 'getPaymentWays',
        currencies: 'getFilteredCurrencies',
      }),
      filteredActiveFilters() {
          return Object.fromEntries(
            Object.entries(this.activeFilters).filter(
              ([key, value]) => key !== 'size' && key !== 'page' && (key === 'is_active' || value !== null)
            )
          );
        },
    },
    data() {
      return {
       loadingFlag: false,
        formLoading: false,
        subTypeMenu: false,
        typeMenu: false,
        currencyMenu: false,
        activeFilters:{},
        filters: {
          type: '',
          bonus_type: '',
          sub_type: '',
          min_dep: null,
          max_dep: null,
          wager: null,

          page: 1,
          size: 25,
        },
        depBonusDialog: false,
        deletingBonus: {},
        confirmDeleteDialog: false,
        types:[
          {
            name: this.$t('next_deposit'),
            value: 'next_deposit',
          },
          {
            name: this.$t('no_deposit'),
            value: 'no_deposit',
          },
          {
            name: this.$t('promocode'),
            value: 'promocode',
          },
          {
            name: this.$t('welcome'),
            value: 'welcome',
          },
        ],
        subTypes: [
          {
            name: 'Non Sticky',
            value: 'NON_STICKY',
          },
          {
            name: 'Sticky',
            value: 'STICKY',
          },
        ]
      }
    },
    watch: {
  },
    async mounted() {
      this.loadingFlag = true;
      if (this.depositBonuses.items.length === 0 ) {
          await this.applyFilters()
        }
      if (this.payments.length === 0 ) {
        await this.$store.dispatch('getPaymentWays');
      }
      this.loadingFlag = false;
    },
    methods: {
      getBonusActive(bonus) {
        switch (bonus) {
        case true:
            return this.$t('active');
        case false:
        return this.$t('not_active');
        default:
        return this.$t('not_active');
      }
    },
    getBonusActiveClass(bonus) {
      switch (bonus) {
        case true:
            return "_green";
        case false:
            return "_red";
        default:
        return "_gray";
      }
    },
      getFilterValueTitle(key) {
      // chip keys
      switch (key) {
        case 'country_name':
          return this.$t('country')
        case 'blocked':
          return this.$t('blocked')
      }

      return key;
    },
    getFilterValueText(key, value) {
      // chip values
      switch (value) {
        case true:
          return this.$t('yes')
        case false:
          return this.$t('no')
      }
      if (key === 'type') {
        return this.$t(value)
      } else{

        return value;
      }
    },
    async removeFilter(key) {
      this.loadingFlag = true;
      // Delete chip
      this.$delete(this.activeFilters, key);
      // empty string
      if (typeof this.filters[key] === 'boolean') {
        this.$set(this.filters, key, false);
      } else {
        this.$set(this.filters, key, '');
      }
      this.applyFilters();
    },
    clearAllFilters() {
      this.loadingFlag = true;
      const page = this.filters.page
      const size = this.filters.size
      Object.keys(this.filters).forEach((key, index) => {
        this.$delete(this.filters, key);
      })
      Object.keys(this.activeFilters).forEach((key, index) => {
        this.$delete(this.activeFilters, key);
      })
      this.filters.page = page;
      this.filters.size = size
      this.applyFilters();
    },
    holdActiveFilters(){
        for (const [key, value] of Object.entries(this.filters)) {
          if (value !== '') {
            this.$set(this.activeFilters, key, value);
          } else {
            this.$delete(this.activeFilters, key, value);
          }
        }
      },
    //========================================================================================================================================================
    
      getSubType(subType) {
        switch (subType) {
          case 'NON_STICKY':
            return 'Non Sticky';
          case 'STICKY':
            return 'Sticky';
          default:
            return subType;
        }
      },
      closeMenus(){
        this.subTypeMenu = false;
        this.typeMenu = false;
        this.currencyMenu = false;
      },
      async clearSelectedFilter(filter){
        this.filters[filter] = null;
        await this.applyFilters();
      },
      debounceFilters: debounce(async function() {
        await this.applyFilters();
      }, 550),
      async applyFilters(){
        this.loadingFlag = true;
        this.$store.commit('paggination/SET_FILTERS', this.filters);
        this.closeMenus();
        try {
          await this.$store.dispatch('bonuses/awaitGetDepositBonuses', this.filters);
          
        } catch (error) {
          this.$toast.add({
            severity: 'error', summary: this.$t("just_error"), life: 4000,
          })
        } finally{
          this.holdActiveFilters();
          this.$store.commit('paggination/SET_FILTERS', this.filters);
          this.loadingFlag = false;
          this.$store.commit('paggination/SET_LOADING_FLAG', false);
        }
      },
      toggleConfirmDeleteDialog(bonus) {
        this.deletingBonus = bonus;
        this.confirmDeleteDialog = true;
      },
      async toEditBonus(bonus) {
        this.$router.push(`/deposit-bonuses/edit/${bonus.id}`);
      },
      async deleteBonus() {
        this.formLoading = true;
        try {
          await this.$store.dispatch('bonuses/deleteDepositBonus', this.deletingBonus.id);
          this.confirmDeleteDialog = false;
          await this.$store.dispatch('bonuses/getdepositBonuses');
        } catch (error) {
          this.$toast.add({ severity: 'error', summary: this.$t('just_error'),
          detail: error,
          life: 4000 });
        } finally{

          this.formLoading = false;
        }
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  